/* Desktop View CSS */

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.loginPageContainer {
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.loginPageLeftAndRightContent {
  margin-top: 36px;
  flex-grow: 1;
}

.loginLeftContent {
  height: 100%;
}

.leftImageWrapper {
  height: 100%;
  background: #fff;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 60px;
}

.loginRightContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.formWrapper {
  width: 100%;
}

@media (min-width: 1152px) {
  .loginPageLeftAndRightContent {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .loginLeftContent {
    /* width: 48%; */
    height: 100%;
    /* padding: 22px; */
  }

  .loginRightContent {
    width: 50%;
    /* padding: 12px; */
  }
  .formWrapper {
    width: 70%;
  }
}

/* Smaller Screens View CSS */

@media (min-width: 800px) and (max-width: 1152px) {
  .loginPageLeftAndRightContent {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .loginLeftContent {
    display: none;
  }

  .loginRightContent {
    width: 50%;
    /* padding: 12px; */
  }
}

/* Tablet View CSS */

@media (min-width: 768px) and (max-width: 800px) {
  .loginLeftContent {
    display: none;
  }
  .formWrapper {
    width: 70%;
  }
}

/* Mobile View CSS */

@media (min-width: 320px) and (max-width: 768px) {
  .loginLeftContent {
    display: none;
  }
}
