@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@100..900&display=swap");
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #e9f5fe;
  font-family: "IBM Plex Sans", "Poppins", sans-serif; /* Use Inter as primary, Poppins as fallback */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* overflow-x: hidden; */
}

/* #root {
  width: 100vw;
  min-height: 100vh;
} */

.button-float-css {
  float: right;
}

.button-hover-css:hover {
  cursor: pointer;
}

.delete-button-css {
  color: #a6a6a6;
  cursor: pointer;
  margin-top: 6px;
}

.profiles-hover-css:hover {
  background-color: #bde7f1;
}

.delete-button-css:hover {
  color: red;
}

.button-focus-css:focus {
  box-shadow: none !important;
}

.file-drop {
  /* relatively position the container bc the contents are absolute */
  position: relative;
  height: 100px;
  width: 100%;
}

.file-drop > .file-drop-target {
  /* basic styles */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;

  /* horizontally and vertically center all content */
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;

  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;

  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;

  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  align-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;

  text-align: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  /* overlay a black mask when dragging over the frame */
  border: none;
  /* background-color: rgba(0, 0, 0, 0.65); */
  box-shadow: none;
  z-index: 50;
  opacity: 1;

  /* typography */
  color: white;
}

.close-button {
  background: transparent;
  border: none;
  color: #fff;
}

.file-name-container-1 {
  background-color: #7e9ad6;
  border-radius: 10px;
  padding: 16px;
}

.file-name-container-2 {
  background-color: #4d79db;
  border-radius: 10px;
  padding: 16px;
}

.file-name-container-3 {
  background-color: #3167e0;
  border-radius: 6px;
  padding: 16px;
  font-family: "Roboto";
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  gap: 10px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
}

.heading_spaces {
  letter-spacing: normal;
}
tbody {
  display: block;
  max-height: 43vh;
  overflow: auto;
}

thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.selectTag {
  border: 1px solid rgba(221, 224, 225, 1);
  border-radius: 5px;
  outline: none;
  height: 30px;
  width: 425px;
  padding-left: 15px;
}
.historicButtons {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  align-items: center;
  width: 200px;
  justify-content: space-between;
}
.patientDetailsTableBody {
  display: block;
  /* max-height: 43vh; */
  /* overflow: auto; */
}

.alertError {
  text-transform: none;
}
.zipAlReports {
  background-color: transparent;
  border: 1px solid black;
}
.zipAlReports:hover {
  background-color: #282c34;
  border: none;
  color: white;
}
